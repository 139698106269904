import { useLayoutEffect, useState, useRef, useEffect, useMemo } from 'react';

import { Wax } from 'wax-prosemirror-core';
import { debounce } from 'lodash';
import EditoriaLayout from './layout/EditoriaLayout';
import config from './config/config';
import { demo } from './demo';
import 'wax-prosemirror-core/dist/index.css'
import 'wax-prosemirror-services/dist/index.css'
import 'wax-table-service/dist/index.css'
import { Button, Col, Row } from 'reactstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getRMSDocument, postRevisedData } from '../documents/apiController';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import axios from 'axios';

const renderImage = file => {
  const reader = new FileReader();
  return new Promise((accept, fail) => {
    reader.onload = () => accept(reader.result);
    reader.onerror = () => fail(reader.error);
    // Some extra delay to make the asynchronicity visible
    setTimeout(() => reader.readAsDataURL(file), 150);
  });
};

const user = {
  userId: 'b3cfc28e-0f2e-45b5-b505-e66783d4f946',
  userColor: {
    addition: 'royalblue',
    deletion: 'indianred',
  },
  username: 'admin',
};



const Editor = (props) => {
  const [width] = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();
  const editorRef = useRef();
  const [docData, setDocData] = useState(location.state)
  const [documentData, setDocumentData] = useState();
  const [response, setResponse] = useState();
  const [count, setCount] = useState(0);
  const [permission, setPermission] = useState(false);
  let documentName = "";

  /**
 * get document data if URL is from RMS
 */
  const getDocumentDataRms = async (path, id) => {
    try {
      let docResponse = await getRMSDocument(path, id);
      console.log(docResponse.data.revision.content)
      setResponse(docResponse.data)
      //docResponse.data && setDocumentData(docResponse.data.revision.content)
      let parsedJSON = JSON.parse(localStorage.getItem(docResponse.data.revision.content));
      //console.log(docResponse.data.revision.content)
      setDocumentData(docResponse.data.revision.content)
    } catch (error) {
      if (path == 'm1') {
        getDocumentDataRms('c1', id)
      } else {
        showToaster(toasterTypes.ERROR, "To proceed, you may need to request access from the administrator.")
      }
    }
  }

  /**
   * set document data
   */
  const getDocumentData = () => {
    documentName = localStorage.getItem('documentSelected');
    let parsedJSON = JSON.parse(localStorage.getItem(documentName));
    setDocumentData(parsedJSON)
  }



  useEffect(() => {

    console.log(localStorage.getItem('docInfo'))
    if (!location.state) {
      setDocData(JSON.parse(localStorage.getItem('docInfo')))
    }
    let data = location.state;
    console.log(data)
    setPermission(data.docPermission)
    localStorage.setItem("docEdit", data.docPermission)
    if (typeof location.state == "string" && location.state.includes("rms")) {
      const params = new URLSearchParams(window.location.href);
      const docId = params.get('docId');
      const token = params.get('token');
      console.log("---->ID", params.get('docId'))
      localStorage.setItem("rms_token", token)
      getDocumentDataRms('m1', docId);
    } else {
      getDocumentData();
    }
  }, [])



  /**
   * onclick documents
   */
  const onDocumentAction = async () => {
    console.log(location.state)
    onSaveDocument();
    if (location.state) {

      let data = location.state;
      if (typeof data == "string" && data.includes("rms")) {
      } else {
        console.log(data.data)
        navigate('/project', { state: { data: data.data, type: "document" } })
      }
    } else {
      navigate('/project', { state: { data: JSON.parse(localStorage.getItem('projectData')), type: "document" } })
    }
  }

  /**
   * on save
   * create json format 
   */
  const onSaveDocument = async () => {
    let data = location.state;
    let docId = localStorage.getItem("documentSelected")
    let parsedJSON = JSON.parse(localStorage.getItem(docId));

    let Obj = {
      "topic_id": data.data.TopicID,
      "project_id": data.data.id,
      "doc_id": data.docInfo.id,
      "content": parsedJSON
    }

    try {
      let saveDocResponse = await postRevisedData(Obj);
      if (saveDocResponse) {
        showToaster(toasterTypes.SUCCESS, "Document saved successfully!!")
      }
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    console.log("asdf")
  }, [permission])


  return <>
    {/* <Row className='page-header m-0' >
      <Col className={window.innerWidth > 750 ? "project-title" : "project-title-mob"} md={11}>
        <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => onDocumentAction()} />
        {
          typeof location.state == "string" ?
            <span style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }}>{response && response.name}</span>
            :
            <span style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }}>{docData && docData.docInfo.name}</span>

        }
      </Col>
      <Col className='p-0'>
      </Col>
    </Row> */}
    {

      documentData && typeof location.state == "string" && location.state.includes("rms") &&
      <Wax
        ref={editorRef}
        config={config}
        autoFocus
        placeholder="Type Something..."
        fileUpload={file => renderImage(file)}
        value={documentData}
        readonly
        targetFormat='JSON'
        layout={EditoriaLayout}
        // addSection={addSection}
        onChange={debounce(source => {
          console.log((JSON.stringify(source)))
          localStorage.setItem(localStorage.getItem('documentSelected'), (JSON.stringify(source)));
        }, 200)}
        user={user}
        scrollMargin={500}
        scrollThreshold={500}
      />
    }
    {
      permission == false &&
      <div style={{ overflow: "hidden" }}>
        <Wax
          ref={editorRef}
          config={config}
          key={1}
          autoFocus
          readonly
          placeholder="Type Something..."
          fileUpload={file => renderImage(file)}
          value={documentData}
          targetFormat='JSON'
          layout={EditoriaLayout}
          // addSection={addSection}
          onChange={debounce(source => {
            //console.log((JSON.stringify(source)))
            localStorage.setItem(localStorage.getItem('documentSelected'), (JSON.stringify(source)));
          }, 200)}
          user={user}
          scrollMargin={500}
          scrollThreshold={500}
        />
      </div>
    }
    {
      documentData && typeof location.state != "string" && permission != false &&
      <div style={{ overflow: "hidden" }}>
        <Wax
          ref={editorRef}
          config={config}
          key={1}
          autoFocus
          placeholder="Type Something..."
          fileUpload={file => renderImage(file)}
          value={documentData}
          targetFormat='JSON'
          layout={EditoriaLayout}
          // addSection={addSection}
          onChange={debounce(source => {
            //console.log((JSON.stringify(source)))
            localStorage.setItem(localStorage.getItem('documentSelected'), (JSON.stringify(source)));
          }, 200)}
          user={user}
          scrollMargin={500}
          scrollThreshold={500}
        />
      </div>
    }



  </>;
};

function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default Editor;
