import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import HistogramChart from "../charts/HistogramChart";
import BoxPlot from "../charts/BoxPlotChart";
import StemAndLeafChart from "../charts/SteamAndLeafChart";
import QQChart from "../charts/QQChart";

export default function NumericalAnalysisCharts(props) {
    const { data } = props;
    const [histoData, sethistoData] = useState<any>([]);
    const [chartArray, setchartArray] = useState<any>([]);

    useEffect(() => {
        let Obj: any = [];
        if (data?.rawdata) {
            Object.keys(data.rawdata).map(items => {
                Obj.push(parseFloat(data.rawdata[items].toFixed(3)))
            })
            sethistoData(prevState => [{ [data.title]: Obj }])
        }

    }, [])

    useEffect(() => {
        console.log(chartArray)
    }, [histoData, chartArray])

    return (
        <>
            <div className="numeric-charts">
                <Row>
                    {
                        data && data?.charts && data.charts.map(chartitem => {
                            return <>
                                {
                                    chartitem == "histogram" ?
                                        histoData && histoData.map((item,i) => {
                                            return <Col md={6}>
                                                <HistogramChart containerid={"Histogram"+i} data={item[Object.keys(item)[0]]} title={Object.keys(item)[0]} width={500} height={300} />
                                            </Col>
                                        })
                                        :
                                        chartitem == "boxplot" ?
                                            histoData && histoData.map((item, i) => {
                                                return <Col md={6}>
                                                    <BoxPlot
                                                        containerid={"Boxplot" + i}
                                                        data={[item[Object.keys(item)[0]]]}
                                                        label={[Object.keys(item)[0]]}
                                                        width={500}
                                                        height={350}
                                                        chartstyle={{ color: "lightblue", hover: "#ccc", dotradius: 5, linecolor: "#ccc" }}
                                                        chartdatastyle={{ fontsize: "12px", fontweight: "500", color: "black", visible: "visible" }}
                                                        chartdots={{ color: "#000", radius: 0 }}
                                                        gridcolor={"#ccc"}
                                                        gridstatus={"visible"}
                                                        yaxistext={chartArray.yaxis}
                                                        yaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "#000" }}
                                                        yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000", dy: "-2em" }}
                                                        xaxistext={chartArray.xaxis}
                                                        xaxislabelstyle={{ fontsize: "12px", fontweight: "500", color: "#000", rotate: "-45" }}
                                                        xaxisstyle={{ fontsize: "14px", fontweight: "500", color: "#000", dy: "0em" }}
                                                        title={" "}
                                                        titlecolor={{ fontsize: "24px", fontweight: "600", color: "green" }} />
                                                </Col>
                                            })
                                            :
                                            chartitem == "qqplot" ?
                                                histoData && histoData.map((item,i) => {
                                                    return <Col md={6}>
                                                        <QQChart containerid={"QQplot" + i}
                                                            data={item[Object.keys(item)[0]]} width={400} height={300} />
                                                    </Col>
                                                })
                                                :
                                                chartitem == "stemleaf" ?
                                                    histoData && histoData.map((item,i) => {
                                                        return <Col md={6}>
                                                            <StemAndLeafChart containerid={"Stem"+i} data={item[Object.keys(item)[0]]} width={400} height={300} />
                                                        </Col>
                                                    })
                                                    :
                                                    <></>
                                }
                            </>
                        })
                    }
                </Row>
            </div>
        </>
    )
}