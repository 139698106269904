import { Suspense, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { getAnalysisData } from "./apiController";
import PieChart from "../common/charts/PieChart";
import CustomTable from "../../widgets/table";
import BarChart from "../common/charts/BarChart";
import MultiHeadCustomTable from "../../widgets/table/multiheadindex ";
import { Correlation, CrossTabCol, CrossTabRow, IndependentTTest, IndependentTTestRow, KruskalWallis, MannWhitney, OneWayAnnova, OneWayAnnovaRow, PairedTTest, SpearCorrelation, Wilcoxon } from "../common/analysisComponents/AnalysisTables";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import NumericalAnalysisCharts from "../common/analysisComponents/NumericalAnalysisCharts";
import SectionModal from "../Editor/utils/SectionModal";
import { htmlToJSON, parseTable } from "../Editor/utils/ContentType";
import PushToEditor from "../Editor/utils/PushToEditor";
import { showToaster, toasterTypes } from "../../widgets";

export default function AnalysisReport(props) {
    const [data, Setdata] = useState<any>([]);
    const [count, setcount] = useState(0)
    const [updatecompo, setupdate] = useState<any>([])
    const [analysisData, SetAnalysisData] = useState<any>([]);
    const [numericData, SetNumericData] = useState<any>([]);
    const [combinedData, SetCombinedData] = useState<any>([]);
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    let counttable = 0;
    const dispatch = useDispatch();

    /**
* function call to Push code to editor
*/
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }


    const getTableJson = (data) => {

        const table = document.querySelector(selectedTable || '.custom-table');
        let response: any = "";
        if (tableType == "multi") {
            response = parseTable(table,selectedTable)
        } else {
            response = htmlToJSON(table, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }
           
        })
        setactionPush("")
    };

    /**
  * 
  * @param type 
  * @param data 
  */
    const onAction = async (type, data: any = null, i: any = null) => {
        switch (type) {
            case 17:
                setTableType("")
                setSelectedTable(data)
                setactionPush("section")
                break;
            case 18:
                console.log(data)
                setTableType("multi")
                setSelectedTable(data)
                setactionPush("section")
                break;
            default:
                setactionPush(type);
                break;
        }
    }

    /**
     * onchange field value update the array value and update the table value
     * and also update the chart value
     * @param Data 
     * @param id 
     */
    const updateData = (Data, id: any) => {
        let index = analysisData && analysisData.findIndex(item => item.table == id);
        let filterData = analysisData && analysisData.filter(item => item.table == id);
        let DataType = false;
        let updateObject = {};

        let tabUpdateData: any = [];
        if (typeof Data != "string") {
            Data.map(value => {
                tabUpdateData.push({
                    variable: value[id],
                    frequency: value.frequency,
                    percentage: value.percentage,
                })
            })

            updateObject = {
                column: filterData[0].column,
                data: DataType ? Data : tabUpdateData,
                tabledata: Data,
                table: id,
                ylabel: filterData[0].ylabel,
                xlabel: filterData[0].xlabel,
                title: filterData[0].title,
                head: filterData[0].head
            }
        } else {
            updateObject = {
                column: filterData[0].column,
                data: filterData[0].data,
                tabledata: filterData[0].tabledata,
                table: id,
                ylabel: filterData[0].ylabel,
                xlabel: filterData[0].xlabel,
                title: filterData[0].title,
                head: Data
            }
        }



        let removeData = analysisData && analysisData.filter(item => item.table != id);

        removeData.splice(index, 0, updateObject);
        SetAnalysisData(removeData)

    }

    const updateChild = (data) => {
        setupdate(data)
        console.log(data)
    }
    /**
     * onchange numerical data update the values
     * remove item from state array 
     * update the state array with updated data
     */
    const updateNumericData = (Data, id: any) => {
        let updatedData = {};
        if (typeof Data != "string") {
            updatedData = {
                title: numericData[id].title,
                tablenumericdata: Data,
                column: numericData[id].column,
                head: numericData[id].head
            }
        } else {
            updatedData = {
                title: numericData[id].title,
                tablenumericdata: numericData[id].tablenumericdata,
                column: numericData[id].column,
                head: Data
            }
        }
        numericData.splice(id, 1); //remove item with index
        numericData.splice(id, 0, updatedData); //update new data on same index
        SetNumericData(numericData)//update state data
        setcount(count + 1)
    }

    /**
     * onChange combined data updata table and chart
     */
    const updateCombinedData = (Data, id: any, tablenum: any) => {
        console.log(Data, id, tablenum)
        SetCombinedData(combinedData)
        if (tablenum == 1) {
            combinedData[id].tabledata = Data;
        } else if (tablenum == 2) {
            combinedData[id].tabledata1 = Data;
        } else {
            combinedData[id].tabledata3 = Data;
        }
        setcount(count + 1)
    }
    useEffect(() => {
        console.log(props)
        fetchInitialData();
    }, [])

    useEffect(() => {

    }, [analysisData, count, updatecompo])

    /**
     * fetch intial data
     * create data structure for each analysis type
     * push data to state catergorical, numerical, combined data
     */
    const fetchInitialData = async () => {
        try {
            dispatch(setLoading(true))
            console.log(props)
            let response = await getAnalysisData(props.formData?.id, props?.selectedItem.id);
            Setdata(response)
            //data preparation for categorical
            let chartData: any = [];
            let i = 0;
            for (var value in response.categoricalAnalysis) {
                i++;
                let variablename: any = value;
                let data: any = [];
                let columns: any = [];
                let tabledata: any = [];
                let totalCount = 0;

                columns.push({
                    id: "variablename",
                    label: variablename,
                    accessor: "variablename",
                    sort: "asc",
                }, {
                    id: 'frequency',
                    label: "Frequency",
                    accessor: 'frequency',
                    sort: "asc",
                }, {
                    id: 'percentage',
                    label: "Percentage",
                    accessor: 'percentage',
                    sort: "asc",
                });

                for (var valkey in response.categoricalAnalysis[value]['percentages']['proportion']) {
                    let percent = response.categoricalAnalysis[value]['percentages']['proportion'][valkey] * 100
                    let frequency = response.categoricalAnalysis[value]['frequencies']['count'][valkey]
                    let variabledata = response.categoricalAnalysis[value]['percentages'][value][valkey]
                    totalCount += frequency;
                    data.push({
                        percentage: percent.toFixed(2).replace(/\.00$/, ''),
                        frequency: frequency.toFixed(2).replace(/\.00$/, ''),
                        variable: variabledata,
                        error: 0
                    });
                    console.log(variablename, variabledata)
                    tabledata.push({
                        percentage: percent.toFixed(2).replace(/\.00$/, ''),
                        frequency: frequency.toFixed(2).replace(/\.00$/, ''),
                        variablename: variabledata
                    });
                }

                console.log(tabledata)

                // response.categoricalAnalysis[value].rowvar.map(item => {
                //     //let percentage = response.categoricalAnalysis[value].percentages[item].toFixed(2).replace(/\.00$/, '') * 100
                //     let percentage = 23;
                //     data.push({
                //         percentage: percentage.toFixed(),
                //         frequency: 23,
                //         variable: item
                //     });

                //     tabledata.push({
                //         percentage: percentage.toFixed(),
                //         frequency: 12,
                //         [variablename]: item
                //     });
                // })
                //create final array of objects with data sets for table and chart
                chartData.push({
                    data: data,
                    tabledata: tabledata,
                    column: columns,
                    table: value.toLowerCase().replace(" ", ""),
                    ylabel: 'Percentage(%)',
                    xlabel: value,
                    title: value,
                    head: "Table " + `${i}` + ": Descriptive analysis of " + `${value}` + ` in the study population (N=${totalCount})`
                })

            }
            SetAnalysisData(chartData)

            //data preparation for Numerical
            let numericArrayData: any = [];
            let j = 0;
            for (var value in response.numericalAnalysis) {
                j++;
                let variablename: any = value.toLowerCase().replace(" ", "");
                let columns: any = [];
                let tablenumericdata: any = [];
                let totalCount = 0;

                columns.push({
                    id: 'name',
                    label: 'Name',
                    accessor: 'name',
                    sort: "asc",
                }, {
                    id: 'mean',
                    label: "Mean ± S.D",
                    accessor: 'mean',
                    sort: "asc",
                }, {
                    id: 'median',
                    label: "Median",
                    accessor: 'median',
                    sort: "asc",
                }, {
                    id: 'minimum',
                    label: "Minimum",
                    accessor: 'minimum',
                    sort: "asc",
                }, {
                    id: 'maximum',
                    label: "Maximum",
                    accessor: 'maximum',
                    sort: "asc",
                }, {
                    id: 'ci',
                    label: "95% CI",
                    columns: [
                        {
                            id: 'lower',
                            label: "Lower CI",
                            accessor: 'lower',
                        },
                        {
                            id: 'upper',
                            label: "Upper CI",
                            accessor: 'upper',
                        }
                    ]
                });

                tablenumericdata.push({
                    name: value,
                    mean: response.numericalAnalysis[value].mean.toFixed(2) + "±" + response.numericalAnalysis[value].std.toFixed(2),
                    median: response.numericalAnalysis[value].median.toFixed(2),
                    minimum: response.numericalAnalysis[value].min.toFixed(2),
                    maximum: response.numericalAnalysis[value].max.toFixed(2),
                    lower: response.numericalAnalysis[value].ci_lower.toFixed(2),
                    upper: response.numericalAnalysis[value].ci_upper.toFixed(2)
                })

                numericArrayData.push({
                    title: value,
                    tablenumericdata: tablenumericdata,
                    column: columns,
                    charts: response.numericalAnalysis[value].charts,
                    rawdata: response.numericalAnalysis[value].rawdata,
                    head: "Table " + `${j}` + ": Descriptive analysis of " + `${value}` + ` in the study population (N=${response.numericalAnalysis[value].count})`
                })
            }

            SetNumericData(numericArrayData)
            //let temp = JSON.parse(response)
            SetCombinedData(response.configAnalysis)

            dispatch(setLoading(false))

        } catch (error) {
            dispatch(setLoading(false))
        }

    }

    return (
        <>
            <div className="analysis-report-container">
                <div className="header-container">
                    <Row className='m-0'>
                        <Col className='pl-3 mt-3'>
                            <div className='form-name'>
                                <img className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                                Data Analysis
                            </div>
                        </Col>
                        <Col className='pr-4 mt-4 text-right'>
                            <i className='icon icon-close' onClick={() => props.onClose()} />
                        </Col>

                    </Row>
                </div>
                <div className="report">
                    <div className="cart">
                        {analysisData && analysisData.length > 0 ? <h5>Categorical Analysis</h5> : ""}
                        {
                            analysisData && analysisData.map((item, i) => {
                                return (
                                    <>
                                        <div className="tabledata">
                                            {
                                                actionPush == "section" &&
                                                <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                                            }
                                            <CustomTable
                                                isEditable={true}
                                                header={`${item.head}`}
                                                data={item.tabledata}
                                                tableid={item.table}
                                                tableProps={item.column}
                                                getdata={updateData}
                                                customClassName={"category" + i}
                                                isPushToDoc={true}
                                                onAction={(type, data = null) => onAction(type, ".category" + i)}
                                            />
                                        </div>
                                        <Row className="chartdata">
                                            <Col md={6}>
                                                <PieChart
                                                    containerid={"Pie" + i}
                                                    data={item.data}
                                                    width={500}
                                                    height={300}
                                                    chartStyle={{ hover: "#ccc", fontsize: "14px", fontweight: "500", color: "#fff", height: 30 }}
                                                    title={"Pie Chart of " + item.title}
                                                    titlecolor={{ fontsize: "14px", fontweight: "600", color: "#000" }}
                                                    legends={{ fontsize: "14px", fontweight: "300", color: "#000" }} />
                                            </Col>
                                            <Col md={6}>
                                                <BarChart
                                                    containerid={"Bar" + i}
                                                    data={item.data}
                                                    width={500}
                                                    height={300}
                                                    interval={10}
                                                    barstyle={{ color: "blue", hover: "#ccc" }}
                                                    bardatastyle={{ fontsize: "14px", fontweight: "500", color: "#000" }}
                                                    gridcolor="#ccc"
                                                    gridstatus="hidden"
                                                    yaxistext={item.ylabel}
                                                    yaxislabelstyle={{ fontsize: "12px", fontweight: "200", color: "red" }}
                                                    yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                                    xaxistext={item.xlabel}
                                                    xaxislabelstyle={{ fontsize: "12px", fontweight: "400", color: "red", rotate: "-25" }}
                                                    xaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                                    title={"Bar Chart of " + item.title}
                                                    titlecolor={{ fontsize: "14px", fontweight: "600", color: "#000" }}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )
                            })
                        }



                        {numericData && numericData.length > 0 ? <h5>Numerical Analysis</h5> : ""}
                        <Suspense fallback={<h2>Loading....</h2>}>
                            {
                                numericData && numericData.map((item, i) => {
                                    return (
                                        <>
                                            <div className="numericdata">
                                                <MultiHeadCustomTable
                                                    isEditable={true}
                                                    header={item.head}
                                                    data={item.tablenumericdata}
                                                    tableProps={item.column}
                                                    tableid={i}
                                                    tableNum={i}
                                                    getdata={updateNumericData}
                                                    isPushToDoc={true}
                                                    customClassName={"numeric" + i}
                                                    onAction={(type, data = null) => onAction(type, '.numeric' + i)} />
                                            </div>
                                            <NumericalAnalysisCharts data={item} />

                                        </>
                                    )
                                })
                            }
                        </Suspense>

                        {combinedData && combinedData.length > 0 ? <h5>Configured Analysis</h5> : ""}
                        {
                            combinedData && combinedData.map((item, i) => {
                                return item.map((items, j) => {
                                    counttable++
                                    return items.tabletype.toLowerCase().includes("crosstabs - rowproportion") ?
                                        <><CrossTabRow data={items} id={counttable} keyValue={j} />
                                        </>
                                        :
                                        items.tabletype.toLowerCase().includes("crosstabs - colproportion") ?
                                            <><CrossTabCol data={items} id={counttable} />
                                            </>
                                            :
                                            items.tabletype.toLowerCase().includes("independent") ?
                                                <><IndependentTTest data={items} chartvalue={updatecompo} id={counttable} updateComp={updateChild} />
                                                    <IndependentTTestRow data={items} id={counttable} updateComp={updateChild} /></>
                                                :
                                                items.tabletype.toLowerCase().includes("mann") ?
                                                    <Suspense fallback={<h2>Loading....</h2>}>
                                                        <MannWhitney data={items} id={counttable} />
                                                    </Suspense>
                                                    :
                                                    items.tabletype.toLowerCase().includes("one way anova") ?
                                                        <><OneWayAnnova data={items} id={counttable} />
                                                            <OneWayAnnovaRow data={items} id={counttable} />
                                                        </>
                                                        :
                                                        items.tabletype.toLowerCase().includes("kruskal–wallis") ?
                                                            <KruskalWallis data={items} id={counttable} />
                                                            :
                                                            items.tabletype.toLowerCase().includes("correlation table") && !items.tabletype.toLowerCase().includes("spearman") ?
                                                                <Correlation data={items} id={counttable} />
                                                                :
                                                                items.tabletype.toLowerCase().includes("spearman correlation") ?
                                                                    <SpearCorrelation data={items} id={counttable} />
                                                                    :
                                                                    items.tabletype.toLowerCase().includes("paired t test") ?
                                                                        <PairedTTest data={items} id={counttable} />
                                                                        :
                                                                        items.tabletype.toLowerCase().includes("wilcoxon") ?
                                                                            <Wilcoxon data={items} id={counttable} />
                                                                            :
                                                                            ""

                                })
                            })
                        }

                    </div>
                </div>
            </div>
        </>
    )
}