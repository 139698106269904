import { useEffect, useState } from "react"
import { Col, Row } from "reactstrap";
import BarChart from "../charts/BarChart";
import PieChart from "../charts/PieChart";
import StackedBarChart from "../charts/StackedBarchart";
import LineChart from "../charts/LineChart";
import GroupedBarChart from "../charts/GroupedBarchart";
import ScatterPlot from "../charts/ScatterPlotChart";
import BoxPlot from "../charts/BoxPlotChart";
import HorizontalBarChart from "../charts/HorizonatClusterBarChart";
import ParetoChart from "../charts/ParetoChart";
import ErrorBarChart from "../charts/ErrorBarChart";
import HorizontalClusterBarChart from "../charts/HorizonatClusterBarChart";

const AnalysisChart = (props) => {
    const [chartData, SetchartData] = useState<any>([]);

    useEffect(() => {
        console.log(props)
        if (props.type == 1) {
            let data = createChartData(props.data)
            data.then(data => {
                SetchartData(data)
            })
        } else if (props.type == 2) {
            let data = createChartMeanMedian(props.data)
            data.then(data => {
                SetchartData(data)
            })
        } else if (props.type == 3) {
            let data = createChartMedian(props.data)
            data.then(data => {
                SetchartData(data)
            })
        } else if (props.type == 4) {
            let data = createChartCorrelation(props.data)
            data.then(data => {
                SetchartData(data)
            })
            console.log(data)
        } else if (props.type == 5) {
            let data = createChartPairedTest(props.data)
            data.then(data => {
                SetchartData(data)
            })
        }
    }, [])


    /**
     * chart using median values
     * @param data 
     * @returns 
     */
    async function createChartMedian(data) {
        let dataArray: any = [];
        let charttype = "";
        let xlabel = "";
        let ylabel = data?.colvariablenames[0];
        let chartArray: any = [];
        data.charts && data.charts.map(item => {
            //let item = data.charts;

            if (item == "bar" || item == "horizontalbarchart") {
                dataArray = [];

                data?.rowvariablevalues.map(item => {
                    dataArray.push({
                        percentage: parseInt(data.tabledata[item]["median"].toFixed(2)),
                        frequency: data.tabledata[item]["median"].toFixed(2),
                        variable: item,
                        error: parseInt(data.tabledata[item]["std"] ? data.tabledata[item]["std"].toFixed(2).replace(/\.00$/, '') : 0)

                    })
                })
                charttype = item;
                xlabel = data?.rowvariablenames;
                ylabel = "Median"
                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "pie") {
                let totalMean = 0;
                dataArray = [];
                data?.rowvariablevalues.map(item => {
                    totalMean += data.tabledata[item]["median"];
                })

                data?.rowvariablevalues.map(item => {
                    dataArray.push({
                        percentage: parseInt((data.tabledata[item]["median"] * 100 / totalMean).toFixed(2)),
                        frequency: data.tabledata[item]["median"].toFixed(2) * 100 / totalMean,
                        variable: item
                    })

                })
                charttype = item;
                xlabel = data?.rowvariablenames;
                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "clusterBarchart") {
                let valueArray: any = [];
                dataArray = [];

                data?.rowvariablevalues.map(item => {
                    valueArray.push({ name: item, value: data?.tabledata[item]["median"] && parseInt(data?.tabledata[item]["median"].toFixed(2)) })
                })
                charttype = item;
                xlabel = data?.rowvariablenames[0];
                dataArray.push({ group: 'Median', values: valueArray })

                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })

            } else if (item == "stackedbarchart") {
                let valueObject: any = {};
                dataArray = [];
                let categoriesArray: any = [];
                valueObject['label'] = data?.rowvariablenames[0];
                data?.rowvariablevalues.map(item => {
                    categoriesArray.push(item)
                    valueObject[item] = parseInt(data?.tabledata[item]["median"].toFixed(2))
                })
                charttype = item;
                xlabel = data?.rowvariablenames[0];
                dataArray.push(valueObject)

                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, categories: categoriesArray, yaxis: ylabel })
            } else if (item == "linechart") {
                let valueArray: any = []; dataArray = [];

                data?.rowvariablevalues.map(item => {
                    valueArray.push({ x: item, y: parseInt(data?.tabledata[item]["median"].toFixed(2)) })
                })
                charttype = item;
                xlabel = data?.rowvariablenames[0];
                dataArray.push(valueArray)
                chartArray.push({ charttype: charttype, data: valueArray, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "boxplot") {
                dataArray = [];

                for (var val in data?.rawdata) {
                    let valueArray: any = [];

                    for (var valitem in data?.rawdata[val]) {
                        valueArray.push(data?.rawdata[val][valitem])
                    }
                    dataArray.push(valueArray.sort())
                }

                charttype = item;
                xlabel = data?.rowvariablenames[0];
                chartArray.push({ charttype: charttype, label: data?.rowvariablevalues.reverse(), data: dataArray, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "paretochart") {
                dataArray = [];
                data?.rowvariablevalues.map(item => {
                    dataArray.push({
                        frequency: data.tabledata[item]["median"].toFixed(2),
                        value: parseInt(data.tabledata[item]["median"].toFixed(2).replace(/\.00$/, '')),
                        label: item
                    })
                })
                charttype = item;
                xlabel = data?.rowvariablenames;
                ylabel = "Median"
                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })
            }
        })

        return chartArray;
    }


    //chart for crosstabs
    async function createChartData(data) {
        let dataArray: any = [];
        let charttype = "";
        let xlabel = "";
        let ylabel = data?.colvariablenames;
        let chartArray: any = [];
        data && data?.charts.map(item => {
            if (item == "bar" || item == "pie") {
                item = "clusterBarhart"
            }

            if (item == "clusterBarchart") {
                for (var val in data?.tabledata) {
                    let valueArray: any = [];
                    data?.colvariablevalues[data?.colvariablenames].map(item => {
                        valueArray.push({ name: item, value: parseFloat(data.tabledata[val][item + "_per"].toFixed(2)) })
                    })
                    charttype = item;
                    xlabel = data?.rowvariablenames;
                    dataArray.push({ group: val, values: valueArray })

                }
                console.log(dataArray)
                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "horizontalbarchart") {
                for (var val in data?.tabledata) {
                    let valueArray: any = [];
                    data?.colvariablevalues[data?.colvariablenames].map(item => {
                        valueArray.push({ name: item, value: parseFloat(data.tabledata[val][item + "_per"].toFixed(2)) })
                    })
                    charttype = item;
                    xlabel = data?.rowvariablenames;
                    dataArray.push({ group: val, values: valueArray })
                }
                console.log(dataArray)
                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })

            } else if (item == "pie") {
                for (var val in data.tabledata) {
                    data?.colvariablevalues[data?.colvariablenames].map(item => {
                        dataArray.push({
                            percentage: data.tabledata[val][item + "_per"].toFixed(2),
                            frequency: data.tabledata[val][item + "_per"].toFixed(2),
                            variable: item
                        })
                    })
                    charttype = data?.charts;
                    xlabel = data?.rowvariablenames;
                }
                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })
            } else if (data?.charts == "bar1") {
                data.rowvariablevalues[data?.rowvariablenames].map(item => {
                    dataArray.push({
                        percentage: data.tabledata[item]["mean"].toFixed(2).replace(/\.00$/, ''),
                        frequency: data.tabledata[item]["mean"].toFixed(2),
                        variable: item
                    })
                })
            }
        })
        return chartArray
    }

    /**
     * function can be used for all charts with mean values
     * @param data 
     * @returns 
     */
    async function createChartMeanMedian(data) {
        let dataArray: any = [];
        let charttype = "";
        let xlabel = "";
        let ylabel = "";
        let chartArray: any = [];
        data.charts && data.charts.map(item => {
            //let item = data.charts;
            ylabel = data?.colvariablenames[0]
            if (item == "bar" || item == "horizontalbarchart") {
                dataArray = [];
                data?.rowvariablevalues.map(item => {
                    dataArray.push({
                        percentage: parseInt(data.tabledata[item]["mean"].toFixed(2).replace(/\.00$/, '')),
                        frequency: data.tabledata[item]["mean"].toFixed(2),
                        variable: item,
                        error: parseInt(data.tabledata[item]["std"] ? data.tabledata[item]["std"].toFixed(2).replace(/\.00$/, '') : 0)
                    })
                })
                charttype = item;
                xlabel = data?.rowvariablenames[0];
                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "pie") {
                let totalMean = 0;
                dataArray = [];
                data?.rowvariablevalues.map(item => {
                    totalMean += data.tabledata[item]["mean"];
                })

                data?.rowvariablevalues.map(item => {
                    dataArray.push({
                        percentage: parseInt((data.tabledata[item]["mean"] * 100 / totalMean).toFixed(2)),
                        frequency: data.tabledata[item]["mean"].toFixed(2) * 100 / totalMean,
                        variable: item
                    })

                })
                charttype = item;
                xlabel = data?.rowvariablenames;

                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "clusterBarchart") {
                let valueArray: any = [];
                dataArray = [];

                data?.rowvariablevalues.map(item => {
                    valueArray.push({ name: item, value: parseInt(data?.tabledata[item]["mean"].toFixed(2)) })
                })
                charttype = item;
                xlabel = data?.rowvariablenames[0];
                dataArray.push({ group: 'Mean', values: valueArray })

                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })

            } else if (item == "stackedbarchart") {
                let valueObject: any = {};
                dataArray = [];
                let categoriesArray: any = [];
                valueObject['label'] = data?.rowvariablenames[0];
                data?.rowvariablevalues.map(item => {
                    categoriesArray.push(item)
                    valueObject[item] = parseInt(data?.tabledata[item]["mean"].toFixed(2))
                })
                charttype = item;
                xlabel = data?.rowvariablenames[0];
                dataArray.push(valueObject)

                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, categories: categoriesArray, yaxis: ylabel })
            } else if (item == "linechart") {
                let valueArray: any = []; dataArray = [];
                data?.rowvariablevalues.map(item => {
                    valueArray.push({ x: item, y: parseInt(data?.tabledata[item]["mean"].toFixed(2)) })
                })
                charttype = item;
                xlabel = data?.rowvariablenames[0];
                dataArray.push(valueArray)
                chartArray.push({ charttype: charttype, data: valueArray, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "boxplot") {
                let valueArray: any = [];
                dataArray = [];

                for (var val in data?.rawdata) {
                    for (var valitem in data?.rawdata[val]) {
                        valueArray.push(data?.rawdata[val][valitem])
                    }
                    dataArray.push(valueArray.sort())
                }

                charttype = item;
                xlabel = data?.rowvariablenames[0];
                chartArray.push({ charttype: charttype, label: data?.rowvariablevalues.reverse(), data: dataArray, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "paretochart") {
                dataArray = [];

                data?.rowvariablevalues.map(item => {
                    dataArray.push({
                        frequency: data.tabledata[item]["median"].toFixed(2),
                        value: data.tabledata[item]["median"].toFixed(2).replace(/\.00$/, ''),
                        label: item
                    })
                })
                charttype = item;
                xlabel = data?.rowvariablenames;
                ylabel = "Median"
                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })
            }
        })
        return chartArray;
    }

    /**
     * charts for correlation
     */
    async function createChartCorrelation(data) {
        let dataArray: any = [];
        let charttype = "";
        let xlabel = "";
        let ylabel = data?.rowvariablenames[0];
        let chartArray: any = [];
        data.charts && data.charts.map(chart => {
            if (chart == "scatterplot") {
                let valueArray: any = []; dataArray = [];
                // data?.rowvariablenames.map((item,i) => {
                //     let ydata = data?.tabledata[i]["r Value"] || data?.tabledata[i]["rs Value(spearman)"]
                //     valueArray.push({ x: item, y: parseInt(ydata.toFixed(4)) })
                // })

                Object.keys(data?.rawdata[data.rowvariablenames[0]]).map(item => {
                    let x = data?.rawdata[data.colvariablenames[0]][item];
                    let y = data?.rawdata[data.rowvariablenames[0]][item];
                    valueArray.push({ x: parseFloat(x.toFixed(2)), y: parseFloat(y.toFixed(2)) })
                })
                console.log(valueArray)
                let sortedData = valueArray.sort((x, y) => { return x.x - y.x });

                charttype = chart;
                xlabel = data?.colvariablenames[0];
                dataArray.push(valueArray)
                chartArray.push({ charttype: charttype, data: sortedData, xaxis: xlabel, yaxis: ylabel })
            }
        });

        return chartArray;

    }

    /**
     * charts for paired T test
     */
    async function createChartPairedTest(data) {
        let dataArray: any = [];
        let charttype = "";
        let xlabel = "";
        let ylabel = data?.colvariablenames[0];
        let chartArray: any = [];
        data.charts && data.charts.map(item => {
            //let item = data.charts;
            ylabel = data?.colvariablenames[0]
            if (item == "bar" || item == "horizontalbarchart" || item == "errorbar") {
                dataArray = [];
                Object.keys(data?.tabledata).map(item => {
                    dataArray.push({
                        percentage: parseInt(data.tabledata[item]["mean"].toFixed(2).replace(/\.00$/, '')),
                        frequency: data.tabledata[item]["mean"].toFixed(2),
                        variable: item,
                        error: parseInt(data.tabledata[item]["std"] ? data.tabledata[item]["std"].toFixed(2).replace(/\.00$/, '') : 0)
                    })
                })
                charttype = item;
                xlabel = data?.rowvariablenames[0];
                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "pie") {
                let totalMean = 0;
                dataArray = [];
                data?.rowvariablevalues.map(item => {
                    totalMean += data.tabledata[item]["mean"];
                })

                data?.rowvariablevalues.map(item => {
                    dataArray.push({
                        percentage: parseInt((data.tabledata[item]["mean"] * 100 / totalMean).toFixed(2)),
                        frequency: data.tabledata[item]["mean"].toFixed(2) * 100 / totalMean,
                        variable: item
                    })

                })
                charttype = item;
                xlabel = data?.rowvariablenames;

                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "clusterBarchart") {
                let valueArray: any = [];
                dataArray = [];

                data?.rowvariablevalues.map(item => {
                    valueArray.push({ name: item, value: parseInt(data?.tabledata[item]["mean"].toFixed(2)) })
                })
                charttype = item;
                xlabel = data?.rowvariablenames[0];
                dataArray.push({ group: 'Mean', values: valueArray })

                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })

            } else if (item == "stackedbarchart") {
                let valueObject: any = {};
                dataArray = [];
                let categoriesArray: any = [];
                valueObject['label'] = data?.rowvariablenames[0];
                data?.rowvariablevalues.map(item => {
                    categoriesArray.push(item)
                    valueObject[item] = parseInt(data?.tabledata[item]["mean"].toFixed(2))
                })
                charttype = item;
                xlabel = data?.rowvariablenames[0];
                dataArray.push(valueObject)

                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, categories: categoriesArray, yaxis: ylabel })
            } else if (item == "linechart") {
                let valueArray: any = []; dataArray = [];
                data?.rowvariablevalues.map(item => {
                    valueArray.push({ x: item, y: parseInt(data?.tabledata[item]["mean"].toFixed(2)) })
                })
                charttype = item;
                xlabel = data?.rowvariablenames[0];
                dataArray.push(valueArray)
                chartArray.push({ charttype: charttype, data: valueArray, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "scatterplot") {
                let valueArray: any = [];
                dataArray = [];
                console.log(data?.rawdata[data.rowvariablenames[0]])
                Object.keys(data?.rawdata[data.rowvariablenames[0]]).map(item => {
                    let x = data?.rawdata[data.rowvariablenames[0]][item];
                    let y = data?.rawdata[data.colvariablenames[0]][item];
                    valueArray.push({ x: parseFloat(x.toFixed(2)), y: parseFloat(y.toFixed(2)) })
                })
                // data?.rowvariablenames.map(item => {
                //     valueArray.push({ x: item, y: parseInt(data?.tabledata[item]["mean"].toFixed(2)) })
                // })
                charttype = item;
                xlabel = data?.colvariablenames[0];
                ylabel = data?.rowvariablenames[0];
                let sortedData = valueArray.sort((x, y) => { return x.x - y.x });
                console.log(sortedData)
                dataArray.push(sortedData);
                chartArray.push({ charttype: charttype, data: sortedData, xaxis: xlabel, yaxis: ylabel })
            } else if (item == "boxplot") {
                let valueArray: any = [];
                dataArray = [];
                let labelsArray: any = [];

                for (var val in data?.rawdata) {
                    labelsArray.push(val)
                    for (var valitem in data?.rawdata[val]) {
                        valueArray.push(data?.rawdata[val][valitem])
                    }
                    dataArray.push(valueArray.sort())
                }

                charttype = item;
                xlabel = data?.rowvariablenames[0];
                chartArray.push({ charttype: charttype, label: labelsArray, data: dataArray, xaxis: xlabel, yaxis: ylabel })
                console.log(chartArray)

            } else if (item == "paretochart") {
                dataArray = [];

                data?.rowvariablevalues.map(item => {
                    dataArray.push({
                        frequency: data.tabledata[item]["median"].toFixed(2),
                        value: data.tabledata[item]["median"].toFixed(2).replace(/\.00$/, ''),
                        label: item
                    })
                })
                charttype = item;
                xlabel = data?.rowvariablenames;
                ylabel = "Median"
                chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })
            }
        })
        return chartArray;
    }


    useEffect(() => {
        //console.log(chartData)
    }, [chartData])

    return (
        <>
            <Row className="chartdata">
                {
                    chartData && chartData.map((item, i) => {
                        return <Col md={6} className="p-1 mt-2">{item?.charttype == "bar" ?
                            <BarChart
                                containerid={"Bar" + i}
                                data={item.data}
                                width={500}
                                height={300}
                                interval={10}
                                barstyle={{ color: "blue", hover: "#ccc" }}
                                bardatastyle={{ fontsize: "12px", fontweight: "500", color: "#000" }}
                                gridcolor="#ccc"
                                gridstatus="hidden"
                                yaxistext={item.yaxis}
                                yaxislabelstyle={{ fontsize: "12px", fontweight: "200", color: "red" }}
                                yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                xaxistext={item.xaxis}
                                xaxislabelstyle={{ fontsize: "10px", fontweight: "400", color: "red", rotate: "-20" }}
                                xaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                title={""}
                                titlecolor={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                            />
                            :
                            item?.charttype == "errorbar" ?
                                <ErrorBarChart
                                    containerid={"Errorbar" + i}
                                    data={item.data}
                                    width={500}
                                    height={300}
                                    interval={10}
                                    barstyle={{ color: "blue", hover: "#ccc" }}
                                    bardatastyle={{ fontsize: "12px", fontweight: "500", color: "#000" }}
                                    gridcolor="#ccc"
                                    gridstatus="hidden"
                                    yaxistext={item.yaxis}
                                    yaxislabelstyle={{ fontsize: "12px", fontweight: "200", color: "red" }}
                                    yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                    xaxistext={item.xaxis}
                                    xaxislabelstyle={{ fontsize: "10px", fontweight: "400", color: "red", rotate: "-20" }}
                                    xaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                    title={""}
                                    titlecolor={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                />
                                : item?.charttype == "pie" ?
                                    <PieChart
                                        containerid={"Pie" + i}
                                        data={item.data}
                                        width={500}
                                        height={300}
                                        chartStyle={{ hover: "#ccc", fontsize: "14px", fontweight: "500", color: "#fff", height: 1 }}
                                        title={""}
                                        titlecolor={{ fontsize: "14px", fontweight: "600", color: "#000" }}
                                        legends={{ fontsize: "14px", fontweight: "300", color: "#000" }} />
                                    : item?.charttype == "clusterBarchart" ?
                                        <GroupedBarChart
                                            containerid={"grouped" + i}
                                            data={item.data}
                                            width={500}
                                            height={300}
                                            barstyle={{ color: "#80cbc4", hover: "#ccc" }}
                                            bardatastyle={{ fontsize: "12px", fontweight: "400", color: "black", visible: "visible" }}
                                            gridcolor={"#ccc"}
                                            gridstatus={"hidden"}
                                            yaxistext={item.yaxis}
                                            yaxislabelstyle={{ fontsize: "12px", fontweight: "400", color: "#000" }}
                                            yaxisstyle={{ fontsize: "12px", fontweight: "400", color: "#000", dy: "-2em" }}
                                            xaxistext={item.xaxis}
                                            xaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "red", rotate: "-45" }}
                                            xaxisstyle={{ fontsize: "14px", fontweight: "400", color: "#000", dy: "1em" }}
                                            title={" "}
                                            titlecolor={"#000"}
                                        />
                                        : item?.charttype == "stackedbarchart" ?
                                            <StackedBarChart
                                                containerid={"Stacked" + i}
                                                data={item.data}
                                                width={500}
                                                height={300}
                                                categories={item.categories}
                                                barstyle={{ color: "#80cbc4", hover: "#ccc" }}
                                                bardatastyle={{ fontsize: "14px", fontweight: "500", color: "red", visible: "visible" }}
                                                gridcolor={"#cc1"}
                                                gridstatus={"visible"}
                                                yaxistext={item.yaxis}
                                                yaxislabelstyle={{ fontsize: "12px", fontweight: "400", color: "#000" }}
                                                yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000", dy: "2em" }}
                                                xaxistext={item.xaxis}
                                                xaxislabelstyle={{ fontsize: "12px", fontweight: "400", color: "red", rotate: "-45" }}
                                                xaxisstyle={{ fontsize: "12px", fontweight: "500", color: "#000", dy: "3em" }}
                                                title={" "}
                                                titlecolor={{ fontsize: "24px", fontweight: "600", color: "green" }} />
                                            : item?.charttype == "linechart" ?
                                                <LineChart
                                                    containerid={"Line" + i}
                                                    datachart={item.data}
                                                    width={500}
                                                    heigth={300}
                                                    scaley={20}
                                                    isarray={true}
                                                    chartstyle={{ color: "royalblue", hover: "#ccc", linecolor: "#cc5", radius: 4 }}
                                                    gridcolor={"#ccc"}
                                                    gridstatus={"hidden"}
                                                    yaxistext={item.yaxis}
                                                    yaxisstyle={{ fontsize: "14px", fontweight: "600", color: "#000", dy: "-2em" }}
                                                    xaxistext={item.xaxis}
                                                    xaxisstyle={{ fontsize: "18px", fontweight: "500", color: "#000", dy: "2em" }}
                                                    title={" "}
                                                    titlecolor={"#000"}
                                                    xaxistitlecolor={"#000"}
                                                    yaxistitlecolor={"#000"}
                                                />
                                                :
                                                item?.charttype == "scatterplot" ?
                                                    <ScatterPlot
                                                        containerid={"Scatter" + i}
                                                        data={item.data}
                                                        width={500}
                                                        height={300}
                                                        chartstyle={{ color: "red", hover: "#ccc", dotradius: 2, linecolor: "#ccc" }}
                                                        chartdatastyle={{ fontsize: "16px", fontweight: "500", color: "black", visible: "visible" }}
                                                        gridcolor={"#ccc"}
                                                        gridstatus={"visible"}
                                                        yaxistext={item.yaxis}
                                                        yaxislabelstyle={{ fontsize: "18px", fontweight: "400", color: "#000" }}
                                                        yaxisstyle={{ fontsize: "14px", fontweight: "600", color: "#000", dy: "-2em" }}
                                                        xaxistext={item.xaxis}
                                                        xaxislabelstyle={{ fontsize: "18px", fontweight: "400", color: "red", rotate: "-45" }}
                                                        xaxisstyle={{ fontsize: "18px", fontweight: "500", color: "#000", dy: "2em" }}
                                                        title={" "}
                                                        titlecolor={{ fontsize: "24px", fontweight: "600", color: "green" }} />

                                                    :
                                                    item?.charttype == "boxplot" ?

                                                        <BoxPlot
                                                            containerid={"Boxplot" + i}
                                                            data={item.data}
                                                            label={item.label}
                                                            width={500}
                                                            height={300}
                                                            chartstyle={{ color: "lightblue", hover: "#ccc", dotradius: 5, linecolor: "#ccc" }}
                                                            chartdatastyle={{ fontsize: "12px", fontweight: "500", color: "black", visible: "visible" }}
                                                            chartdots={{ color: "#000", radius: 0 }}
                                                            gridcolor={"#ccc"}
                                                            gridstatus={"visible"}
                                                            yaxistext={item.yaxis}
                                                            yaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "#000" }}
                                                            yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000", dy: "-2em" }}
                                                            xaxistext={item.xaxis}
                                                            xaxislabelstyle={{ fontsize: "12px", fontweight: "500", color: "#000", rotate: "-45" }}
                                                            xaxisstyle={{ fontsize: "14px", fontweight: "500", color: "#000", dy: "0em" }}
                                                            title={" "}
                                                            titlecolor={{ fontsize: "24px", fontweight: "600", color: "green" }} />
                                                        :
                                                        item?.charttype == "horizontalbarchart" ?
                                                            <HorizontalClusterBarChart
                                                                containerid={"HoriCluster" + i}
                                                                data={item.data}
                                                                width={500}
                                                                height={300}
                                                                barstyle={{ color: "blue", hover: "#ccc" }}
                                                                bardatastyle={{ fontsize: "12px", fontweight: "500", color: "#000" }}
                                                                gridcolor="#ccc"
                                                                gridstatus="hidden"
                                                                yaxistext={item.xaxis}
                                                                yaxislabelstyle={{ fontsize: "12px", fontweight: "200", color: "#000", dy: "-1em" }}
                                                                yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000", rotate: "-45" }}
                                                                xaxistext={item.yaxis}
                                                                xaxislabelstyle={{ fontsize: "10px", fontweight: "400", color: "#000", rotate: "-20" }}
                                                                xaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000", dy: "4em" }}
                                                                title={""}
                                                                titlecolor={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                                            />
                                                            :
                                                            item?.charttype == "paretochart" ?
                                                                <ParetoChart
                                                                    containerid={"Pareto" + i}
                                                                    data={item.data}
                                                                    width={400}
                                                                    height={300}
                                                                    gridcolor={"#ccc"}
                                                                    gridstatus={"visible"}
                                                                    barstyle={{ color: "orange", hover: "#ccc" }}
                                                                    bardatastyle={{ fontsize: "12px", fontweight: "500", color: "black", visible: "visible" }}
                                                                    linestyle={{ color: "blue", hover: "#ccc" }}
                                                                    linedatastyle={{ fontsize: "12px", fontweight: "500", color: "black", radius: 3, visible: "visible" }}
                                                                    title={" "}
                                                                    titlecolor={{ fontsize: "24px", fontweight: "600", color: "green" }}
                                                                    yaxistext={item.yaxis}
                                                                    yaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "#000" }}
                                                                    yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000", dy: "-3em" }}
                                                                    xaxistext={item.xaxis}
                                                                    xaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "#000", rotate: "-45" }}
                                                                    xaxisstyle={{ fontsize: "18px", fontweight: "500", color: "#000", dy: "3em" }}
                                                                />
                                                                :
                                                                ""
                        }
                        </Col>
                    })
                }
            </Row>

        </>
    )
}

export default AnalysisChart;