import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import SectionModal from '../../Editor/utils/SectionModal';
import { downloadChartAsImage } from './downloadChartAsImage';
import { uploadChartAsImage } from '../../Editor/utils/UploadToServer';
import { Button } from 'reactstrap';

const StemAndLeafChart = ({ containerid, data, width, height }) => {
  const svgRef = useRef(null);
  const [actionPush, setactionPush] = useState('');

  /**
  * function call to Push code to editor
  */
  const addToEditor = (data) => {
    console.log(data)
    uploadChartAsImage(data, containerid)
    setactionPush("")
  }

  useEffect(() => {
    if (!data || data.length === 0) return;

    const margin = { top: 20, right: 30, bottom: 50, left: 50 };
    const width = 500 ;
    const height = 300 ;

    const svg = d3
      .select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${width / 4},${height / 6})`);


    // Extracting stems and leaves
    const stems = new Set(data.map(d => Math.floor(d / 10)));
    const leafMap = {};
    data.forEach(d => {
      const stem = Math.floor(d / 10);
      const leaf = d % 10;
      if (!leafMap[stem]) leafMap[stem] = [];
      leafMap[stem].push(leaf);
    });

    const stemValues = Array.from(stems.values()).sort((a, b) => a - b);

    const xScale = d3
      .scaleBand()
      .domain(stemValues.map(d => String(d)))
      .range([0, width])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(stemValues, d => leafMap[d].length)])
      .nice()
      .range([height, 0]);

    // Draw stems
    svg
      .selectAll('.stem')
      .data(stemValues)
      .enter()
      .append('rect')
      .attr('class', 'stem')
      .attr('x', d => xScale(String(d)))
      .attr('y', 0)
      .attr('width', xScale.bandwidth())
      .attr('height', height)
      .attr('fill', 'lightblue')
      .attr('stroke', 'black');

    // Draw leaves
    svg
      .selectAll('.leaf')
      .data(stemValues)
      .enter()
      .selectAll('.leaf')
      .data(d => leafMap[d])
      .enter()
      .append('circle')
      .attr('class', 'leaf')
      .attr('cx', d => xScale(String(d3.select(d))) + xScale.bandwidth() / 2)
      .attr('cy', d => yScale(d) - 20)
      .attr('r', 3)
      .attr('fill', 'steelblue');

    // Add stem labels
    svg
      .selectAll('.stem-label')
      .data(stemValues)
      .enter()
      .append('text')
      .attr('class', 'stem-label')
      .attr('x', d => xScale(String(d)) + xScale.bandwidth() / 2)
      .attr('y', height + 20)
      .text(d => `${d}`)
      .attr('text-anchor', 'middle')
      .attr('font-size', '12px')
      .attr('fill', 'black');

    // Add leaf labels
    svg
      .selectAll('.leaf-label')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'leaf-label')
      .attr('x', d => xScale(String(Math.floor(d / 10))) + xScale.bandwidth() / 2)
      .attr('y', d => yScale(d % 10) - 10)
      .text(d => `${d % 10}`)
      .attr('text-anchor', 'middle')
      .attr('font-size', '12px')
      .attr('fill', 'black');
  }, [data]);

  return <>
    <div className="controller">
      {
        actionPush == "section" &&
        <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
      }

      <Button className='grid' onClick={() => downloadChartAsImage(containerid)}>
        Download
      </Button>
      <Button className='grid' onClick={() => setactionPush("section")}>
        Copy to Ediotor
      </Button>
      {/* <h5>Stem And Leaf Plot</h5> */}
    </div>
    <div id={containerid}>
      <svg ref={svgRef}></svg>
    </div>

  </>
};

export default StemAndLeafChart;
